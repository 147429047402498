export const menu = {
	selector: {
		own: '.js-header-menu',
		mainMenu: '.js-main-menu',
		mainMenuItem: '.js-main-menu-item',
		mainSubmenuHandler: '.js-main-submenu-handler',
		mainMoreMenuItem: '.js-main-more-menu-item',
		mainMoreHandler: '.js-main-more-handler',
		submenu: '.js-submenu',
		submenuHandler: '.js-submenu-handler',
		mobileMenuBtn: '.js-menu-btn',
		mobileMenu: 'data-menu-mobile',
		mobileMenuHandler: 'data-menu-mobile-handler',
	},
	more: {
		threshold: 200,
	},
}
