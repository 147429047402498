import { modifier, module } from 'constant'
import { useEvent, useOverlay, useState, useClickOverlay, useAnimation } from 'hooks'
import { getDrawerSelectors, getDrawer } from 'selectors'
import { cn, object } from 'utils'

type State = {
	drawers?: NodeListOf<HTMLElement>
	drawersHandlers?: NodeListOf<HTMLElement>
	subdrawers?: NodeListOf<HTMLElement>
	subdrawersHandlers?: NodeListOf<HTMLElement>
	subdrawersCloses?: NodeListOf<HTMLElement>
	active: {
		[key: string]: string
	}
	callback?: ModuleCallbacks
}

type ModuleCallbacks = {
	onClose?: {
		[key: string]: () => void
	}
}

const ACTIVE = modifier.active

export const Drawer = () => {
	const { setState, state } = useState<State>({
		active: {},
	})

	const getParentDrawer = (subdrawer: HTMLElement) => {
		const parent = subdrawer.parentNode as HTMLElement

		if (!parent) return null
		const drawer = getDrawer(parent)

		if (!drawer) return null
		return drawer
	}

	const dispatchOnClose = (drawerId: string) => {
		const { callback } = state

		if (!callback) return
		const { onClose } = callback

		if (!onClose || Object.keys(onClose).indexOf(drawerId) < 0) return

		onClose[drawerId]()
	}

	const getDrawerIdBySubdrawerIdFromState = (id: string) => {
		const { active } = state
		const activeKeys = Object.keys(active)
		const activeKeysLength = activeKeys.length
		let r

		if (activeKeysLength === 0) return null
		for (let i = 0; i < activeKeysLength; i++) {
			const activeDrawer = activeKeys[i]

			if (!activeDrawer) continue
			const activeSubdrawer = active[activeDrawer]

			if (!activeSubdrawer || activeSubdrawer !== id) continue
			r = activeDrawer
		}

		return r
	}

	const setHandlersStateByDrawerId = (drawerId: string, activate: boolean) => {
		if (!drawerId) return
		const { drawersHandlers } = state

		if (!drawersHandlers) return
		const drawersHandlersLength = drawersHandlers.length

		for (let j = 0; j < drawersHandlersLength; j++) {
			const drawerHandler = drawersHandlers[j]

			if (!drawerHandler) continue
			const drawerHandlerId = drawerHandler.getAttribute(module.drawer.selector.handler)

			if (!drawerHandlerId || drawerHandlerId !== drawerId) continue
			if (activate) {
				cn.addClass(drawerHandler, ACTIVE)
			} else {
				cn.removeClass(drawerHandler, ACTIVE)
			}
		}
	}

	const setSubdrawerStateById = (id: string, activate: boolean) => {
		if (!id) return
		const { subdrawers } = state

		if (!subdrawers) return
		const subdrawersLength = subdrawers.length

		if (subdrawersLength === 0) return
		for (let i = 0; i < subdrawersLength; i++) {
			const subdrawer = subdrawers[i]

			if (!subdrawer) continue
			const subdrawerId = subdrawer.getAttribute(module.drawer.selector.sub)

			if (!subdrawerId || subdrawerId !== id) continue
			const drawerId = getDrawerIdBySubdrawerIdFromState(subdrawerId)

			if (activate) {
				cn.addClass(subdrawer, ACTIVE)
				useAnimation(
					subdrawer,
					{ transform: 'translateX(-100%)' },
					{ transform: `translateX(${window.innerWidth < module.bp.min.md ? '0' : '100'}%)` },
				)

				if (!drawerId) return
				setState({
					active: {
						...state.active,
						[drawerId]: subdrawerId,
					},
				})
			} else {
				cn.removeClass(subdrawer, ACTIVE)
				useAnimation(
					subdrawer,
					{ transform: `translateX(${window.innerWidth < module.bp.min.md ? '0' : '100'}%)` },
					{ transform: 'translateX(-100%)' },
				)

				if (!drawerId) return
				setState({
					active: {
						...state.active,
						[drawerId]: '',
					},
				})
			}
		}
	}

	const handleCloseActiveDrawers = () => {
		const { drawers, drawersHandlers, active } = state

		if (!drawers || !drawersHandlers) return
		const drawersLength = drawers.length

		if (drawersLength === 0) return
		handleCloseSubdrawers()

		for (let i = 0; i < drawersLength; i++) {
			const drawer = drawers[i]

			if (!drawer) continue
			const drawerId = drawer.getAttribute(module.drawer.selector.own)

			if (!drawerId) continue
			const isDrawerActive = Object.keys(active).indexOf(drawerId) > -1

			if (!isDrawerActive) continue
			cn.removeClass(drawer, ACTIVE)
			setHandlersStateByDrawerId(drawerId, false)
			useAnimation(drawer, { transform: 'translateX(0%)' }, { transform: 'translateX(-100%)' })
			setState({ active: object.omit(drawerId, state.active) })
		}

		useOverlay(false)
	}

	const handleCloseSubdrawers = () => {
		const { subdrawers, active } = state

		if (!subdrawers) return
		const subdrawersLength = subdrawers.length

		if (subdrawersLength === 0) return
		for (let i = 0; i < subdrawersLength; i++) {
			const subdrawer = subdrawers[i]
			const subdrawerId = subdrawer.getAttribute(module.drawer.selector.sub)
			const drawer = getParentDrawer(subdrawer)

			if (!drawer || !subdrawerId) continue
			const drawerId = drawer.getAttribute(module.drawer.selector.own)

			if (!drawerId) continue
			const isDrawerActive = !!active[drawerId] && active[drawerId] === subdrawerId

			if (!isDrawerActive) continue
			cn.removeClass(subdrawer, ACTIVE)
			useAnimation(subdrawer, { transform: 'translateX(100%)' }, { transform: 'translateX(-100%)' })
		}
	}

	const handleSetHandlerEvents = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const { drawers } = state

		if (!drawers) return
		const drawersLength = drawers.length
		const handlerId = handler.getAttribute(module.drawer.selector.handler)
		const handlerSubId = handler.getAttribute(module.drawer.selector.subHandler)

		if (drawersLength === 0 || !handlerId) return
		click.register(({ e }) => {
			e.preventDefault()

			for (let i = 0; i < drawersLength; i++) {
				const drawer = drawers[i]
				const drawerId = drawer.getAttribute(module.drawer.selector.own)

				if (drawerId !== handlerId) continue
				const isDrawerActive = Object.keys(state.active).indexOf(drawerId) > -1

				if (isDrawerActive) {
					dispatchOnClose(drawerId)
					handleCloseSubdrawers()
					setHandlersStateByDrawerId(drawerId, false)
					cn.removeClass(drawer, ACTIVE)
					setState({ active: object.omit(drawerId, state.active) })
				} else {
					setHandlersStateByDrawerId(drawerId, true)
					cn.addClass(drawer, ACTIVE)
					setState({
						active: {
							...state.active,
							[drawerId]: handlerSubId || '',
						},
					})
				}

				useAnimation(
					drawer,
					{ transform: isDrawerActive ? 'translateX(0%)' : 'translateX(-100%)' },
					{ transform: isDrawerActive ? 'translateX(-100%)' : 'translateX(0%)' },
				)

				if (handlerSubId) {
					setSubdrawerStateById(handlerSubId, true)
				}
			}

			useOverlay(Object.keys(state.active).length > 0)
		})
	}

	const handleSetSubhandlerEvents = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const { subdrawers } = state

		if (!subdrawers) return
		const subdrawersLength = subdrawers.length
		const handlerId = handler.getAttribute(module.drawer.selector.subHandler)

		if (subdrawersLength === 0 || !handlerId) return
		click.register(({ e }) => {
			e.preventDefault()
			const { active } = state
			handleCloseSubdrawers()

			for (let i = 0; i < subdrawersLength; i++) {
				const subdrawer = subdrawers[i]
				const subdrawerId = subdrawer.getAttribute(module.drawer.selector.sub)
				const drawer = getParentDrawer(subdrawer)

				if (subdrawerId !== handlerId || !drawer) continue
				const drawerId = drawer.getAttribute(module.drawer.selector.own)

				if (!drawerId) continue
				const isDrawerActive = !!active[drawerId] && active[drawerId] === subdrawerId

				if (isDrawerActive) {
					cn.removeClass(subdrawer, ACTIVE)
					setState({
						active: {
							...state.active,
							[drawerId]: '',
						},
					})
				} else {
					cn.addClass(subdrawer, ACTIVE)
					if (state.active[drawerId].indexOf(subdrawerId) < 0) {
						setState({
							active: {
								...state.active,
								[drawerId]: subdrawerId,
							},
						})
					}
				}

				if (window.innerWidth < module.bp.min.md) {
					useAnimation(
						subdrawer,
						{ transform: isDrawerActive ? 'translateX(0%)' : 'translateX(-100%)' },
						{ transform: isDrawerActive ? 'translateX(-100%)' : 'translateX(0%)' },
					)
				} else {
					useAnimation(
						subdrawer,
						{ transform: isDrawerActive ? 'translateX(100%)' : 'translateX(-100%)' },
						{ transform: isDrawerActive ? 'translateX(-100%)' : 'translateX(100%)' },
					)
				}
			}
		})
	}

	const handleSubdrawerClose = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const handlerId = handler.getAttribute(module.drawer.selector.subClose)

		if (!handlerId) return
		click.register(({ e }) => {
			e.preventDefault()
			setSubdrawerStateById(handlerId, false)
		})
	}

	const initOverlayClick = () => {
		const click = useClickOverlay()

		if (!click) return
		click.register(handleCloseActiveDrawers)
	}

	const initDrawersHandlers = () => {
		const { drawersHandlers } = state

		if (!drawersHandlers) return
		const drawersHandlersLength = drawersHandlers.length

		if (drawersHandlersLength === 0) return
		for (let i = 0; i < drawersHandlersLength; i++) {
			const handler = drawersHandlers[i]

			if (!handler) continue
			handleSetHandlerEvents(handler)
		}
	}

	const initSubdrawersHandlers = () => {
		const { subdrawersHandlers } = state

		if (!subdrawersHandlers) return
		const subdrawersHandlersLength = subdrawersHandlers.length

		if (subdrawersHandlersLength === 0) return
		for (let i = 0; i < subdrawersHandlersLength; i++) {
			const handler = subdrawersHandlers[i]

			if (!handler) continue
			handleSetSubhandlerEvents(handler)
		}
	}

	const initSubdrawersCloses = () => {
		const { subdrawersCloses } = state

		if (!subdrawersCloses) return
		const subdrawersClosesLength = subdrawersCloses.length

		if (subdrawersClosesLength === 0) return
		for (let i = 0; i < subdrawersClosesLength; i++) {
			const close = subdrawersCloses[i]

			if (!close) continue
			handleSubdrawerClose(close)
		}
	}

	const initWindowEvents = () => {
		const resize = useEvent(window, 'resize')
		resize.register(handleCloseActiveDrawers)
	}

	const init = (callback?: ModuleCallbacks) => {
		if (callback) setState({ callback })
		setState({ ...getDrawerSelectors() })

		initDrawersHandlers()
		initSubdrawersHandlers()
		initSubdrawersCloses()
		initOverlayClick()
		initWindowEvents()
	}

	return {
		init,
		closeActiveDrawers: handleCloseActiveDrawers,
	}
}
