export const getTextWidth = (text: string, font: string): number => {
	if (!text || !font) return 0
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	if (!ctx) return 0
	ctx.font = font
	const metrics = ctx.measureText(text)

	return metrics.width
}
