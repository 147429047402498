import Nette from 'nette-forms'
import 'webpack-jquery-ui'
import 'webpack-jquery-ui/resizable'
import 'webpack-jquery-ui/sortable'
import $ from 'jquery'
import spectrum from 'spectrum-colorpicker'
import fineUploader from 'fine-uploader/lib/jquery/traditional'
import ace from 'ace-builds/src-min-noconflict/ace'
import Cookies from 'js-cookie'
import * as HC_ANNOTATIONS from 'highcharts/modules/annotations'

// IE 11 optimalizace
import 'core-js/es/object'
import 'core-js/es/string'
import 'core-js/es/array'
import 'core-js/es/map'
import 'core-js/es/weak-map'
import 'core-js/es/weak-set'

// import './_elis/resizer'
import './_elis/filter/customizedJqueryUi'

import * as Highcharts from 'highcharts'
import * as HC_solidgauge from 'highcharts/modules/solid-gauge'
import * as HC_funnel from 'highcharts/modules/funnel'
import * as HC_exporting from 'highcharts/modules/exporting'
import * as HC_exportData from 'highcharts/modules/export-data'
import * as HC_more from 'highcharts/highcharts-more'
import * as HC_heatmap from 'highcharts/modules/heatmap'
import * as HC_wordcloud from 'highcharts/modules/wordcloud'
import * as HC_regression from 'highcharts-regression'

/* TypeScript */
import { Crosstable, CrosstableEditor, Menu, Header, Resizer, Filter, Drawer, FileManager } from 'modules'
import { getActiveFilters } from 'selectors'

/* JavaScript */
import { client } from './client/client'
import { alerts } from './_elis/alerts'
import { handleBarAnimation } from './_elis/bar_animation'
import { resizetrigger } from './_elis/resizer'

import * as appFunctions from './_elis/$_functions'
import * as filterController from './_elis/filter-controller'
import * as responsiveMenu from './_elis/responsive_menu'
import * as blockResizer from './_elis/modules/editor/blockresizer'
import * as f from './_elis/filter/filter'
import * as srcExport from './_assets/zcanvas/scrExport'
import * as RGBColor from './_assets/zcanvas/rgbcolor'
import * as generatePassword from './_elis/generatepassword'

if ($) {
	$.extend({ spectrum })
	$.extend({ fineUploader })
}

window.jQuery = $
window.$ = $
window.Cookies = Cookies

// Nette Forms
window.Nette = Nette

// Settings
window.blockAttributeData = {}

// Ace
window.ace = ace

// Highcharts
HC_wordcloud(Highcharts)
HC_heatmap(Highcharts)
HC_more(Highcharts)
HC_solidgauge(Highcharts)
HC_funnel(Highcharts)
HC_exporting(Highcharts)
HC_exportData(Highcharts)
HC_ANNOTATIONS(Highcharts)
HC_regression(Highcharts)
window.Highcharts = Highcharts

// App functions
window.loadGraphs = appFunctions.loadGraphs
window.log = appFunctions.log
window.show = appFunctions.show
window.debounce = appFunctions.debounce
window.makeDebounceAjaxCall = appFunctions.makeDebounceAjaxCall
window.resizetrigger = resizetrigger

// Filter
window.itemChecker = f.itemChecker
window.ajaxChange = f.ajaxChange
window.filterDate = f.filterDate
window.filterSelectChoose = f.filterSelectChoose
window.filterSelectSearch = f.filterSelectSearch
window.filterSelectUpdate = f.filterSelectUpdate

// Responsive menu
window.app = responsiveMenu.app

// Alerts
window.alertAction = alerts.alertAction
window.openAlertModal = alerts.openAlertModal
window.closeAlertModal = alerts.closeAlertModal
window.alertsEvents = alerts.alertsEvents

// Block resizer
window.blocksAdjustment = blockResizer.blocksAdjustment
window.resize = blockResizer.resize
window.sort = blockResizer.sort
window.grider = blockResizer.grider
window.setChosenDeviceToVariable = blockResizer.setChosenDeviceToVariable
window.setBlocksHeight = blockResizer.setBlocksHeight
window.sendResizeAjax = blockResizer.sendResizeAjax
window.pageViewForResponsiveResize = blockResizer.pageViewForResponsiveResize
window.cleanPageViewForResponsiveResize = blockResizer.cleanPageViewForResponsiveResize
window.addResponsiveResizeOverlay = blockResizer.addResponsiveResizeOverlay
window.addAttrForResize = blockResizer.addAttrForResize
window.setBlocksHeightRecalculation = blockResizer.setBlocksHeightRecalculation
window.settingsResponsiveMenu = blockResizer.settingsResponsiveMenu
window.toastForSmallWindowForResize = blockResizer.toastForSmallWindowForResize

// Filter controller
window.showHelp = filterController.showHelp

// Generate Password
window.copyToClipboard = generatePassword.copyToClipboard
window.checkPasswordStrength = generatePassword.checkPasswordStrength

// Src export
window.capture = srcExport.capture
window.exportPdf = srcExport.exportPdf
window.nextStep = srcExport.nextStep
window.resizeScreen = srcExport.resizeScreen

// RGB color
window.RGBColor = RGBColor.RGBColor

document.addEventListener('DOMContentLoaded', () => {
	const filter = Filter()
	const drawer = Drawer()
	const header = Header()
	const menu = Menu()
	const resizer = Resizer()
	const crosstable = Crosstable()
	const crosstableEditor = CrosstableEditor()
	const fm = FileManager()

	header.init({ shouldScrolledModifier: !getActiveFilters() })
	menu.init()
	resizer.init()
	drawer.init({ onClose: { filters: filter.submitFilters } })
	filter.init({ onReset: [drawer.closeActiveDrawers] })
	crosstable.init()
	crosstableEditor.init({ reinitCrosstable: crosstable.init })
	fm.init()

	alerts.alertsEvents()
	client()
	handleBarAnimation()

	$.nette.ext({
		success: payload => {
			header.init({ shouldScrolledModifier: !getActiveFilters() })
			drawer.init({ onClose: { filters: filter.submitFilters } })
			filter.init({ onReset: [drawer.closeActiveDrawers] })
			if (payload && payload.reinitMenu === true) {
				menu.init()
			}
			if (payload && payload.redrawCharts === true) {
				appFunctions.loadGraphs()
				if (typeof window._svgheight === 'function') {
					window._svgheight()
				}
				crosstable.init()
				client().initSegmentationFilter()
			}
		},
	})
})

document.addEventListener('load', () => {
	// init on load
	responsiveMenu.app.loader()
})
window.onresize = responsiveMenu.app.resizer
