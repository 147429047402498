import { module } from 'constant'
import { createSelector } from 'selectors'

const { fileManager } = module

const getFoldersSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: fileManager.selector.folder },
		true,
	)

const getSublistsSelector = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: fileManager.selector.sublist },
		true,
	)

const getSublistSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: fileManager.selector.sublist })
const getFolderIconSelector = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: fileManager.selector.folderIcon })

export const getFileManagerSelectors = () => ({
	folders: getFoldersSelector(),
	sublists: getSublistsSelector(),
})

export const getSublist = (scope: HTMLElement) => {
	if (!scope) return null
	const sublist = getSublistSelector(scope)

	if (!sublist) return null
	return sublist
}

export const getFolderIcon = (scope: HTMLElement) => {
	if (!scope) return null
	const icon = getFolderIconSelector(scope)

	if (!icon) return null
	return icon
}
