import { element } from 'utils'

type Scope = Document | Window | HTMLElement | Element | Node
type Context = { [key: string]: any }
type SubscribeCallback = () => void
type RegisterCallback<E> = (callback: {
	next: () => void
	context: Context
	setContext: (values: Context) => void
	e: E
}) => void
type UseEvent<E> = {
	register: (callback: RegisterCallback<E>) => void
	subscribe: (callback: SubscribeCallback) => void
}

let scopes = [] as Scope[]

export const useEvent = <E>(scope: Scope, type: string): UseEvent<E> => {
	const subscribes = [] as SubscribeCallback[]
	const events = [] as ((e: E) => Promise<any>)[]
	let context = {} as Context

	const setContext = (values: Context) => {
		context = {
			...context,
			...values,
		}
	}

	const register = (callback: RegisterCallback<E>) => {
		const promise = (e: E) =>
			new Promise((next, rej) => {
				try {
					if (callback) callback({ next, context, setContext, e })
				} catch (err) {
					rej(err)
				}
			})
		events.push(promise)
	}

	const subscribe = (callback: SubscribeCallback) => {
		subscribes.push(callback)
	}

	const dispatchAllSubscribes = () => {
		if (!subscribes) return
		const subscribesLength = subscribes.length
		for (let i = 0; i < subscribesLength; i++) {
			const dispatchSubscribe = subscribes[i]
			dispatchSubscribe()
		}
	}

	if (scope && type) {
		const scopeCallback = async (e: any) => {
			if (!events) return
			const eventsLength = events.length

			if (eventsLength === 0) return
			for (let i = 0; i < eventsLength; i++) {
				const event = events[i]
				await event(e)
			}
			dispatchAllSubscribes()
		}

		scopes = scopes.filter(item => element.elementExists(item))

		if (scopes.indexOf(scope) < 0) {
			scopes.push(scope)
			scope.addEventListener(type, scopeCallback, true)
		}
	}

	return {
		register,
		subscribe,
	}
}
