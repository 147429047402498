type SelectorProps = {
	scope?: HTMLElement
	selector: string
	value?: string
}

const getSelectorSyntax = (selector: string, value?: string) => {
	if (selector.indexOf('data') > -1 && !value) return `[${selector}]`
	if (selector.indexOf('data') > -1 && value) return `[${selector}="${value}"]`
	return selector
}

export const createSelector = <R extends HTMLElement | NodeListOf<HTMLElement>>(
	{ scope, selector, value }: SelectorProps,
	more = false,
): R => {
	const s = scope || document
	const sel = getSelectorSyntax(selector, value)
	if (more) return s.querySelectorAll<HTMLElement>(sel) as R
	return s.querySelector<HTMLElement>(sel) as R
}
