import { module } from 'constant'
import { createSelector } from 'selectors'

const getHeaderSelector = (scope?: HTMLElement) => createSelector({ scope, selector: module.header.selector.own })
const getHeaderLogoSelector = (scope?: HTMLElement) => createSelector({ scope, selector: module.header.selector.logo })
const getHeaderFilterHandlerSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.header.selector.filterHandler })
const getHeaderActionsSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.header.selector.actions })
const getHeaderAccountSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.header.selector.account })

export const getHeaderSelectors = () => {
	const header = getHeaderSelector()

	if (!header) return null
	return {
		header,
		headerLogo: getHeaderLogoSelector(header),
		filterHandler: getHeaderFilterHandlerSelector(header),
		headerActions: getHeaderActionsSelector(header),
		headerAccount: getHeaderAccountSelector(header),
	}
}

export const getHeader = () => {
	const header = getHeaderSelector()

	if (!header) return null
	return header
}
