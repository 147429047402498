import { useEvent } from 'hooks'
import { cn } from 'utils'
import { modifier } from 'constant'

const ACTIVE = modifier.active

export const Resizer = () => {
	const handleActivateResizer = () => {
		const activateButton = document.querySelector<HTMLElement>('.js-resizer-activator')
		const resizeOptions = document.querySelector<HTMLElement>('.js-resize-options')

		if (!activateButton || !resizeOptions) return
		const click = useEvent(activateButton, 'click')
		click.register(() => {
			const settings = document.getElementById('settings')

			if (!settings) return
			window.scrollTo(0, 0)

			if (cn.hasClass(activateButton, ACTIVE)) {
				cn.removeClass(activateButton, ACTIVE)
				cn.removeClass(resizeOptions, ACTIVE)
			} else {
				cn.addClass(activateButton, ACTIVE)
				cn.addClass(resizeOptions, ACTIVE)
			}
		})
	}

	const init = () => {
		handleActivateResizer()
	}

	return {
		init,
	}
}
