import { module } from 'constant'
import { createSelector, getHeader } from 'selectors'

const getMenuSelector = (scope?: HTMLElement) => createSelector({ scope, selector: module.menu.selector.own })
const getMobileMenuButtonSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.menu.selector.mobileMenuBtn })
const getMobileMenusSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.mobileMenu },
		true,
	)
const getMobileMenusHandlersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.mobileMenuHandler },
		true,
	)
const getSubmenusSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.submenu },
		true,
	)
const getSubmenusHandlersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.submenuHandler },
		true,
	)
const getMainMenuSelector = (scope?: HTMLElement) => createSelector({ scope, selector: module.menu.selector.mainMenu })
const getMainMenuItemsSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.mainMenuItem },
		true,
	)
const getMainMoreMenuItemsSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.mainMoreMenuItem },
		true,
	)
const getMainMoreHandlerSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.menu.selector.mainMoreHandler })
const getMainSubmenusHandlersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.menu.selector.mainSubmenuHandler },
		true,
	)

export const getMenuSelectors = () => {
	const header = getHeader()

	if (!header) return null
	return {
		menu: getMenuSelector(header),
		mobileMenuButton: getMobileMenuButtonSelector(header),
		mobileMenus: getMobileMenusSelector(header),
		mobileMenusHandlers: getMobileMenusHandlersSelector(header),
		submenus: getSubmenusSelector(header),
		submenusHandlers: getSubmenusHandlersSelector(header),
		mainMenu: getMainMenuSelector(header),
		mainMenuItems: getMainMenuItemsSelector(header),
		mainMoreMenuItems: getMainMoreMenuItemsSelector(header),
		mainMoreHandler: getMainMoreHandlerSelector(header),
		mainSubmenusHandlers: getMainSubmenusHandlersSelector(header),
	}
}

export const getMenu = () => {
	const header = getHeader()

	if (!header) return null
	const menu = getMenuSelector(header)

	if (!menu) return null
	return menu
}
