import { easing, styler, tween, TweenProps } from 'popmotion'

export const useAnimation = (el: HTMLElement, from: TweenProps['from'], to: TweenProps['to'], duration = 300) => {
	const newStyler = styler(el)
	tween({
		from,
		to,
		duration,
		ease: easing.easeInOut,
	}).start(newStyler.set)
}
