const BG_MIN_SM = 480
const BG_MIN_MD = 768
const BG_MIN_LG = 992
const BG_MIN_XL = 1200

export const bp = {
	min: {
		sm: BG_MIN_SM,
		md: BG_MIN_MD,
		lg: BG_MIN_LG,
		xl: BG_MIN_XL,
	},
	max: {
		xs: BG_MIN_SM - 1,
		sm: BG_MIN_MD - 1,
		md: BG_MIN_LG - 1,
		lg: BG_MIN_XL - 1,
	},
}
