import { browser } from './$_functions'

export var app = {
	loader: function() {
		browser.ie()
		$(document).trigger('load:trigger')
	},
	resizer: function() {
		show.charts()
	},
}

window.addEventListener('load', function() {
	// init on load
	app.loader()
})
window.onresize = resizeCallback

function resizeCallback() {
	app.resizer()
}
