import { module, modifier } from 'constant'
import { useEvent, useState, useOutsideClick } from 'hooks'
import { getHeaderSelectors, getMenuSelectors } from 'selectors'
import { cn } from 'utils'

type State = {
	header?: HTMLElement
	headerLogo?: HTMLElement
	filterHandler?: HTMLElement
	headerActions?: HTMLElement
	headerAccount?: HTMLElement
	menu?: HTMLElement
	mobileMenuButton?: HTMLElement
	mobileMenus?: NodeListOf<HTMLElement>
	mobileMenusHandlers?: NodeListOf<HTMLElement>
	submenus?: NodeListOf<HTMLElement>
	submenusHandlers?: NodeListOf<HTMLElement>
	mainMenu?: HTMLElement
	mainMenuItems?: NodeListOf<HTMLElement>
	mainMoreMenuItems?: NodeListOf<HTMLElement>
	mainMoreHandler?: HTMLElement
	mainSubmenuHandlers?: NodeListOf<HTMLElement>
}

const ACTIVE = modifier.active

export const Menu = () => {
	const { state, setState } = useState<State>()
	const resize = useEvent(window, 'resize')

	const handleSubmenuHandler = (handler: HTMLElement) => {
		if (!handler) return
		const submenuParent = handler.parentNode as HTMLElement

		if (!submenuParent) return
		const click = useEvent<MouseEvent>(handler, 'click')

		useOutsideClick(submenuParent, () => {
			cn.removeClass(handler, ACTIVE)
			cn.removeClass(submenuParent, ACTIVE)
		})

		click.register(({ e }) => {
			e.preventDefault()
			if (!cn.hasClass(handler, ACTIVE) && !cn.hasClass(submenuParent, ACTIVE)) {
				cn.addClass(handler, ACTIVE)
				cn.addClass(submenuParent, ACTIVE)
			} else {
				cn.removeClass(handler, ACTIVE)
				cn.removeClass(submenuParent, ACTIVE)
			}
		})
	}

	const handleMobileMenuHandler = () => {
		const { menu, mobileMenuButton } = state

		if (!mobileMenuButton || !menu) return
		const click = useEvent<MouseEvent>(mobileMenuButton, 'click')
		click.register(({ e }) => {
			e.preventDefault()
			if (!cn.hasClass(mobileMenuButton, ACTIVE)) {
				cn.addClass(mobileMenuButton, ACTIVE)
				cn.addClass(menu, ACTIVE)
			} else {
				cn.removeClass(mobileMenuButton, ACTIVE)
				cn.removeClass(menu, ACTIVE)
			}
		})
	}

	const handleButtonsMobileMenus = (mobileMenuHandler: HTMLElement) => {
		if (!mobileMenuHandler) return
		const { mobileMenus, mobileMenusHandlers } = state
		const click = useEvent<MouseEvent>(mobileMenuHandler, 'click')

		if (!mobileMenus || !mobileMenusHandlers) return
		const mobileMenusLength = mobileMenus.length
		const mobileMenusHandlersLength = mobileMenusHandlers.length
		const mobileMenuHandlerId = mobileMenuHandler.getAttribute(module.menu.selector.mobileMenuHandler)

		if (mobileMenusLength === 0 || mobileMenusHandlersLength === 0 || !mobileMenuHandlerId) return
		click.register(() => {
			if (!cn.hasClass(mobileMenuHandler, ACTIVE)) {
				for (let i = 0; i < mobileMenusHandlersLength; i++) {
					const handler = mobileMenusHandlers[i]
					if (!handler) continue
					cn.removeClass(handler, ACTIVE)
				}
				cn.addClass(mobileMenuHandler, ACTIVE)
			} else {
				cn.removeClass(mobileMenuHandler, ACTIVE)
			}

			for (let i = 0; i < mobileMenusLength; i++) {
				const mobileMenu = mobileMenus[i]

				if (!mobileMenu) continue
				const mobileMenuId = mobileMenu.getAttribute(module.menu.selector.mobileMenu)
				cn.removeClass(mobileMenu, ACTIVE)

				if (!mobileMenuId || mobileMenuId !== mobileMenuHandlerId) continue
				if (cn.hasClass(mobileMenuHandler, ACTIVE)) cn.addClass(mobileMenu, ACTIVE)
			}
		})
	}

	const handleMainSubmenuHandler = (mainSubmenuHandler: HTMLElement) => {
		if (!mainSubmenuHandler) return
		const click = useEvent<MouseEvent>(mainSubmenuHandler, 'click')

		click.register(() => {
			if (window.innerWidth > module.bp.min.lg || !mainSubmenuHandler.parentNode) return
			const submenuParent = mainSubmenuHandler.parentNode as HTMLElement

			if (!submenuParent) return
			if (!cn.hasClass(submenuParent, ACTIVE)) {
				cn.addClass(submenuParent, ACTIVE)
				cn.addClass(mainSubmenuHandler, ACTIVE)
			} else {
				cn.removeClass(submenuParent, ACTIVE)
				cn.removeClass(mainSubmenuHandler, ACTIVE)
			}
		})
	}

	const showMainMenuItemsByActualWidth = () => {
		const {
			headerAccount,
			headerActions,
			headerLogo,
			mainMenuItems,
			filterHandler,
			mainMoreMenuItems,
			mainMoreHandler,
		} = state

		if (!headerAccount || !headerActions || !mainMenuItems || !headerLogo || !mainMoreMenuItems) return
		const mainMenuItemsLength = mainMenuItems.length
		const mainMoreMenuItemsLength = mainMoreMenuItems.length
		const itemsEqualToMoreItems = mainMenuItemsLength === mainMoreMenuItemsLength

		if (mainMenuItemsLength === 0 || mainMoreMenuItemsLength === 0 || !itemsEqualToMoreItems) return
		let mainMenuLength = 0
		let leftWidth = headerLogo.offsetWidth
		const headerAccountWidth = headerAccount.offsetWidth
		const headerActionsWidth = headerActions.offsetWidth
		const rightWidth = headerAccountWidth + headerActionsWidth

		if (filterHandler) {
			leftWidth += filterHandler.offsetWidth
		}

		const actualMenuWidth = window.innerWidth - leftWidth - rightWidth - module.menu.more.threshold

		for (let i = 0; i < mainMenuItemsLength; i++) {
			const mainMenuItem = mainMenuItems[i]
			const mainMoreMenuItem = mainMoreMenuItems[i]

			if (!mainMenuItem) continue
			mainMenuLength += mainMenuItem.offsetWidth

			if (mainMenuLength < actualMenuWidth) {
				cn.removeClass(mainMenuItem, modifier.desktopHidden)
				cn.addClass(mainMoreMenuItem, modifier.desktopHidden)
			} else {
				cn.addClass(mainMenuItem, modifier.desktopHidden)
				cn.removeClass(mainMoreMenuItem, modifier.desktopHidden)
			}
		}

		if (!mainMoreHandler) return
		if (mainMenuLength > actualMenuWidth) {
			cn.removeClass(mainMoreHandler, modifier.hidden)
		} else {
			cn.addClass(mainMoreHandler, modifier.hidden)
		}
	}

	const initMobileMenu = () => {
		const { mobileMenus, mobileMenusHandlers } = state

		if (!mobileMenus || !mobileMenusHandlers) return
		const mobileMenusLength = mobileMenus.length
		const mobileMenusHandlersLength = mobileMenusHandlers.length

		if (mobileMenusLength !== mobileMenusHandlersLength) return
		for (let i = 0; i < mobileMenusHandlersLength; i++) {
			const mobileMenuHandler = mobileMenusHandlers[i]
			if (!mobileMenuHandler) continue
			handleButtonsMobileMenus(mobileMenuHandler)
		}

		handleMobileMenuHandler()
	}

	const initMainSubmenus = () => {
		const { mainSubmenusHandlers } = state

		if (!mainSubmenusHandlers) return
		const mainSubmenusHandlersLength = mainSubmenusHandlers.length

		if (mainSubmenusHandlersLength === 0) return
		for (let i = 0; i < mainSubmenusHandlersLength; i++) {
			const mainSubmenuHandler = mainSubmenusHandlers[i]
			handleMainSubmenuHandler(mainSubmenuHandler)
		}
	}

	const initSubmenus = () => {
		const { submenus, submenusHandlers } = state

		if (!submenus || !submenusHandlers) return
		const submenuHandlersLength = submenusHandlers.length

		for (let i = 0; i < submenuHandlersLength; i++) {
			const submenuHandler = submenusHandlers[i]

			if (!submenuHandler) continue
			handleSubmenuHandler(submenuHandler)
		}
	}

	const initWindowEvents = () => {
		showMainMenuItemsByActualWidth()

		resize.register(showMainMenuItemsByActualWidth)
	}

	const init = () => {
		setState({
			...getHeaderSelectors(),
			...getMenuSelectors(),
		})

		initMainSubmenus()
		initSubmenus()
		initMobileMenu()
		initWindowEvents()
	}

	return {
		init,
	}
}
