import { modifier } from 'constant'
import { useEvent } from 'hooks'
import { getOverlay } from 'selectors'
import { cn } from 'utils'

const ACTIVE = modifier.active

export const useOverlay = (show = false, onShow?: () => void, onHide?: () => void) => {
	const overlay = getOverlay()

	if (!overlay) return
	// const click = useEvent<MouseEvent>(overlay, 'click')

	if (show && !cn.hasClass(overlay, ACTIVE)) {
		cn.addClass(overlay, ACTIVE)
		if (onShow) onShow()
	} else {
		cn.removeClass(overlay, ACTIVE)
		if (onHide) onHide()
	}
}

export const useClickOverlay = () => {
	const overlay = getOverlay()

	if (!overlay) return null
	return useEvent<MouseEvent>(overlay, 'click')
}
