export const crosstable = {
	selector: {
		own: '.js-ct',
		content: '.js-ct-content',
		loader: '.js-ct-loader',
		static: '.js-ct-static',
		rows: '.js-ct-rows',
		headRow: '.js-ct-row-head',
		headCol: 'data-ct-col-head',
		headColSplitItem: 'data-ct-col-head-split-item',
		staticRow: 'data-ct-row-static',
		staticCol: 'data-ct-col-static',
		staticColSplit: 'data-ct-static-col-split',
		row: 'data-ct-row',
		col: 'data-ct-col',
		colSplit: 'data-ct-col-split',
		colSplitItem: 'data-ct-col-split-item',
		questionName: 'data-ct-question-name',
		modalActiveRows: '.js-ct-modal-active-rows',
		modalActiveCols: '.js-ct-modal-active-columns',
		modalLegend: '.js-ct-modal-legend',
		modalConfig: '.js-ct-modal-config',
		modalConfigQuestions: '.js-ct-modal-config-questions',
		modalConfigQuestionsItem: '.js-ct-modal-config-questions-item',
		searchInput: '.js-ct-search-input',
		searchableItem: '.js-ct-modal-config-questions-item-searchable',
		searchableAttributes: 'data-searchable-attrs',
		searchableQuestionName: 'data-tooltip',
		editorQuestions: '.js-ct-editor-questions',
		editorAvailableQuestions: '.js-ct-editor-available-questions',
		editorAssignedQuestions: '.js-ct-editor-assigned-questions',
		editorQuestionnaireSelect: '.js-ct-editor-questionnaire-select',
		editorQuestionnaireSubmit: '.js-ct-editor-questionnaire-submit',
		editorQuestion: 'data-ct-editor-question',
		editorQuestionHandler: 'data-ct-editor-question-handler',
	},
	size: {
		font: 12,
		colWidth: 50,
		padding: 8,
	},
}
