import Sortable from 'sortablejs'
import $ from 'jquery'
import M from 'materialize-css'

import { module, modifier } from 'constant'
import { useState, useEvent } from 'hooks'
import {
	getHeadCols,
	getHeadColSplitItems,
	getCols,
	getColsSplitItems,
	getStaticCols,
	getCrosstableSelectors,
	getColsSplits,
} from 'selectors'
import { strings, cn } from 'utils'

type State = {
	content?: HTMLElement
	loader?: HTMLElement
	staticContainer?: HTMLElement
	staticRows?: NodeListOf<HTMLElement>
	staticCols?: NodeListOf<HTMLElement>
	staticColsSplits?: NodeListOf<HTMLElement>
	rowsContainer?: HTMLElement
	rows?: NodeListOf<HTMLElement>
	headRows?: HTMLElement
	activeRows?: HTMLElement
	activeColumns?: HTMLElement
	modalLegend?: HTMLElement
	modalConfig?: HTMLElement
	modalConfigQuestions?: HTMLElement
	headCols?: NodeListOf<HTMLElement>
	cols?: NodeListOf<HTMLElement>
	colsSplitItems?: NodeListOf<HTMLElement>
	firstRow?: HTMLElement
	firstRowCols?: NodeListOf<HTMLElement>
	searchInput: HTMLElement
	searchableItems: NodeListOf<HTMLElement>
}

const { selector, size } = module.crosstable

export const Crosstable = () => {
	const { state, setState } = useState<State>()

	const makeLoader = () => {
		const { content, loader } = state

		if (!content || loader) return
		const fragment = document.createDocumentFragment()
		const newLoader = document.createElement('div')
		const newLoaderShape = document.createElement('div')

		newLoader.setAttribute('class', 'crosstable__loader js-ct-loader')
		newLoaderShape.setAttribute('class', 'crosstable__loader-shape')

		newLoader.appendChild(newLoaderShape)
		fragment.appendChild(newLoader)
		content.appendChild(fragment)
	}

	const destroyLoader = () => {
		const { content, loader } = state

		if (!loader || !content) return
		content.removeChild(loader)
	}

	const makeCrosstableColsWidth = () => {
		const { headCols, cols } = state
		let tableWidths = {} as { [key: string]: { width: number; splits: { [key: string]: number } } }

		if (!headCols) return
		const headColsLength = headCols.length

		if (headColsLength === 0) return
		for (let i = 0; i < headColsLength; i++) {
			const headCol = headCols[i]
			const headColIndex = headCol.getAttribute(`${selector.headCol}`)
			const headColSplitItems = getHeadColSplitItems(headCol)

			if (!headColSplitItems) continue
			const headColSplitItemsLength = headColSplitItems.length

			if (!headColIndex || headColSplitItemsLength === 0) continue
			tableWidths = {
				...tableWidths,
				[headColIndex]: {
					...tableWidths[headColIndex],
					width: headCol.offsetWidth,
				},
			}

			for (let j = 0; j < headColSplitItemsLength; j++) {
				const headColSplitItem = headColSplitItems[j]
				const headColSplitItemIndex = headColSplitItem.getAttribute(`${selector.headColSplitItem}`)

				if (!headColSplitItemIndex) continue
				tableWidths = {
					...tableWidths,
					[headColIndex]: {
						...tableWidths[headColIndex],
						splits: {
							...tableWidths[headColIndex].splits,
							[headColSplitItemIndex]: headColSplitItem.offsetWidth,
						},
					},
				}
			}
		}

		if (!cols) return
		const colsLength = cols.length

		if (colsLength === 0) return
		for (let i = 0; i < colsLength; i++) {
			const col = cols[i]
			const colsSplitItems = getColsSplitItems(col)

			if (!colsSplitItems) continue
			const colSplitItemsLength = colsSplitItems.length
			const colIndex = col.getAttribute(`${selector.col}`)

			if (!colIndex || colSplitItemsLength === 0) continue
			const colWidths = tableWidths[colIndex]

			if (colWidths) {
				const { width } = colWidths
				col.setAttribute('style', `max-width: ${width}px; flex: 0 1 ${width}px;`)
			}

			for (let j = 0; j < colSplitItemsLength; j++) {
				const colSplitItem = colsSplitItems[j]
				const colSplitItemIndex = colSplitItem.getAttribute(selector.colSplitItem)

				if (!colSplitItem || !colSplitItemIndex || !colWidths) continue
				const { splits } = colWidths
				const splitWidth = splits[colSplitItemIndex]
				colSplitItem.setAttribute('style', `max-width: ${splitWidth}px; flex: 0 1 ${splitWidth}px;`)
			}
		}
	}

	const makeStaticColsWidth = () => {
		const { staticCols } = state
		const widths = {} as { [key: string]: number[] }

		if (!staticCols) return
		const staticColsLength = staticCols.length

		if (staticColsLength === 0) return
		for (let i = 0; i < staticColsLength; i++) {
			const staticCol = staticCols[i]

			if (!staticCol) continue
			const staticColIndex = staticCol.getAttribute(`${selector.staticCol}`)

			if (!staticColIndex) continue
			if (!widths[staticColIndex]) widths[staticColIndex] = []
			widths[staticColIndex].push(staticCol.offsetWidth)
		}

		for (let i = 0; i < staticColsLength; i++) {
			const staticCol = staticCols[i]

			if (!staticCol) continue
			const staticColIndex = staticCol.getAttribute(`${selector.staticCol}`)
			const widthsKeys = Object.keys(widths)

			if (!staticColIndex) continue
			if (widthsKeys.indexOf(staticColIndex) < 0) continue
			const staticColWidth = Math.max(...widths[staticColIndex])
			staticCol.setAttribute('style', `max-width: ${staticColWidth}px; flex: 0 1 ${staticColWidth}px;`)
		}
	}

	const makeColsHeightByStatic = () => {
		const { staticContainer, rows, staticRows, staticColsSplits } = state
		let heights = {} as { [key: string]: { [key: string]: number } }

		if (!staticContainer) return
		const lastStaticCols = getStaticCols(2)

		if (!lastStaticCols) return
		const lastStaticColsLength = lastStaticCols.length

		if (!lastStaticColsLength) return
		for (let i = 0; i < lastStaticColsLength; i++) {
			const staticCol = lastStaticCols[i]

			if (!staticCol || !staticColsSplits) continue
			const staticColSplitsLength = staticColsSplits.length

			if (staticColSplitsLength === 0) continue
			for (let j = 0; j < staticColSplitsLength; j++) {
				const staticColSplit = staticColsSplits[j]

				if (!staticColSplit) continue
				const staticColSplitIndex = staticColSplit.getAttribute(selector.staticColSplit)

				if (!staticColSplitIndex) continue
				const height = staticColSplit.offsetHeight
				heights = {
					...heights,
					[i + 1]: {
						...heights[i + 1],
						[staticColSplitIndex]: height,
					},
				}
			}
		}

		if (!rows || !staticRows) return
		const rowsLength = rows.length
		const staticRowsLength = staticRows.length

		if (rowsLength === 0 || staticRowsLength === 0) return
		for (let i = 0; i < rowsLength; i++) {
			const row = rows[i]
			const rowIndex = row.getAttribute(selector.row)

			if (!rowIndex) continue
			const colSplits = getColsSplits(row)

			if (!colSplits) continue
			const colSplitsLength = colSplits.length

			if (colSplitsLength === 0) {
				const staticRow = staticRows[i]

				if (!staticRow) continue
				row.setAttribute('style', `height: ${staticRow.offsetHeight}px;`)
				continue
			}

			for (let j = 0; j < colSplitsLength; j++) {
				const colSplit = colSplits[j]
				const colSplitIndex = colSplit.getAttribute(selector.colSplit)

				if (!colSplitIndex) continue
				const height = heights[rowIndex][colSplitIndex]
				colSplit.setAttribute('style', `height: ${height}px;`)
			}
		}
	}

	const makeStaticHeightAndPosition = () => {
		const { staticContainer, rowsContainer, headRows, staticColsSplits, firstRow, firstRowCols } = state

		if (!staticContainer || !rowsContainer || !headRows) return
		staticContainer.setAttribute('style', `margin-top: ${headRows.offsetHeight}px;`)
		const staticStyle = staticContainer.getAttribute('style')

		if (!firstRow || !firstRowCols) return
		const rowMinHeight = firstRow.offsetHeight
		const firstRowColsLength = firstRowCols.length

		if (firstRowColsLength < 2 && staticStyle) {
			let staticTitleColsHeight = 0
			let staticSplitColsHeight = 0
			const staticTitleCols = getStaticCols(1)
			const staticSplitCols = getStaticCols(2)

			if (!staticTitleCols || !staticSplitCols) return
			const staticTitleColsLength = staticTitleCols.length
			const staticSplitColsLength = staticSplitCols.length

			if (staticTitleColsLength > 0) {
				for (let i = 0; i < staticTitleColsLength; i++) {
					const staticTitleCol = staticTitleCols[i]

					if (!staticTitleCol) continue
					staticTitleColsHeight += staticTitleCol.offsetHeight
				}
			}
			if (staticSplitColsLength > 0) {
				for (let i = 0; i < staticSplitColsLength; i++) {
					const staticSplitCol = staticSplitCols[i]

					if (!staticSplitCol) continue

					if (!staticColsSplits) continue
					const staticSplitColItemsLength = staticColsSplits.length
					let currentHeight = 0

					for (let j = 0; j < staticSplitColItemsLength; j++) {
						const staticSplitColItem = staticColsSplits[j]

						if (!staticSplitColItem) continue
						currentHeight += staticSplitColItem.offsetHeight
					}

					staticSplitColsHeight += currentHeight > rowMinHeight ? currentHeight : rowMinHeight
				}
			}

			if (staticTitleColsHeight > 0 && staticSplitColsHeight > 0) {
				staticContainer.setAttribute(
					'style',
					`${staticStyle} height: ${
						staticTitleColsHeight > staticSplitColsHeight ? staticTitleColsHeight : staticSplitColsHeight
					}px;`,
				)
			}

			makeColsHeightByStatic()
		}

		const rowsContainerStyle = rowsContainer.getAttribute('style')

		if (rowsContainerStyle) {
			rowsContainer.setAttribute('style', `${rowsContainerStyle} height: ${staticContainer.offsetHeight}px;`)
		} else {
			rowsContainer.setAttribute('style', `height: ${staticContainer.offsetHeight}px;`)
		}
	}

	const makeTableContentMinWidth = () => {
		const { rowsContainer, headRows, headCols } = state

		if (!headRows || !rowsContainer || !headCols) return
		const headColsLength = headCols.length

		if (headColsLength < 2) return
		let contentMinWidth = 0

		for (let i = 0; i < headColsLength; i++) {
			const headCol = headCols[i]
			const headColSplitItems = getHeadColSplitItems(headCol)

			if (!headColSplitItems) continue
			const headColSplitItemsLength = headColSplitItems.length

			if (headColSplitItemsLength === 0) continue
			for (let j = 0; j < headColSplitItemsLength; j++) {
				const headColSplitItem = headColSplitItems[j]
				const stringWidth = strings.getTextWidth(headColSplitItem.innerText, `${size.font}pt Roboto`)

				if (stringWidth > size.colWidth) {
					contentMinWidth += stringWidth
				} else {
					contentMinWidth += size.colWidth
				}
				contentMinWidth += size.padding * 2
			}
		}

		headRows.setAttribute('style', `min-width: ${contentMinWidth}px;`)
		rowsContainer.setAttribute('style', `min-width: ${contentMinWidth}px;`)
	}

	const handleTableSizes = () => {
		makeTableContentMinWidth()
		makeStaticColsWidth()
		makeCrosstableColsWidth()
		makeColsHeightByStatic()
		makeStaticHeightAndPosition()
	}

	const handleAjax = (link: string, name: string) => {
		makeLoader()
		// @ts-ignore
		$.nette.ajax({
			url: link.replace('replaceParam', name),
		})
	}
	const handleSortable = () => {
		const { activeRows, activeColumns, modalConfigQuestions } = state

		if (!activeRows || !activeColumns || !modalConfigQuestions) return

		const rowsHandle = activeRows.getAttribute('data-ct-update-link')

		if (!rowsHandle) return
		const sortableActiveColums = new Sortable(activeColumns, {
			group: 'shared',
			animation: 150,
			draggable: '.js-ct-modal-active-columns-item',
			onAdd: e => {
				const { item } = e
				const name = item.getAttribute(selector.questionName)

				if (!name) return
				handleAjax(rowsHandle, name)
			},
			onRemove: e => {
				const { item } = e
				const name = item.getAttribute(selector.questionName)

				if (!name) return
				handleAjax(rowsHandle, name)
			},
		})

		const colsHandle = activeColumns.getAttribute('data-ct-update-link')
		const colsRemoveHandle = activeColumns.getAttribute('data-ct-remove-link')

		const handleQuestionRestrictStart = (e: Sortable.SortableEvent) => {
			const { item } = e
			const isAttribute = cn.hasClass(item, 'js-ct-is-attribute')
			const { parentNode } = activeColumns
			const parent = parentNode as HTMLElement

			if (!isAttribute || !parentNode) return
			sortableActiveColums.options.disabled = true
			parent.setAttribute('style', 'opacity: 0.3;')
		}

		const handleQuestionRestrictEnd = () => {
			const { parentNode } = activeColumns
			const parent = parentNode as HTMLElement

			if (!parentNode) return
			sortableActiveColums.options.disabled = false
			activeColumns.setAttribute('style', 'background-color: transparent;')
			parent.setAttribute('style', 'opacity: 1;')
		}

		if (!colsHandle || !colsRemoveHandle) return
		const sortableActiveRows = new Sortable(activeRows, {
			group: 'shared',
			animation: 150,
			draggable: '.js-ct-modal-active-rows-item',
			onAdd: e => {
				const { item } = e
				const name = item.getAttribute(selector.questionName)

				if (!name) return
				handleAjax(colsHandle, name)
			},
			onRemove: e => {
				const { item } = e
				const name = item.getAttribute(selector.questionName)

				if (!name) return
				handleAjax(colsRemoveHandle, name)
			},
			onStart: e => {
				handleQuestionRestrictStart(e)
			},
			onEnd: () => {
				handleQuestionRestrictEnd()
			},
		})

		const sortableQuestions = new Sortable(modalConfigQuestions, {
			group: 'shared',
			animation: 150,
			draggable: selector.modalConfigQuestionsItem,
			onStart: e => {
				handleQuestionRestrictStart(e)
			},
			onEnd: () => {
				handleQuestionRestrictEnd()
			},
		})
		setState({ sortableQuestions, sortableActiveRows, sortableActiveColums })
	}

	const handleModalEvents = () => {
		const { modalLegend, modalConfig } = state

		if (!modalLegend || !modalConfig) return
		M.Modal.init(modalLegend)
		M.Modal.init(modalConfig)

		// @ts-ignore
		$.nette.ext({
			before: () => {
				makeLoader()
			},
			complete: ({ reinitAll }: { [key: string]: any }) => {
				handleInitSelectors()

				handleTableSizes()
				handleSortable()
				destroyLoader()
				handleModalSearch()

				if (!reinitAll) return
				if (!modalConfig || !state.modalConfig) return
				M.Modal.init(state.modalConfig)

				if (!modalLegend || !state.modalLegend) return
				M.Modal.init(state.modalLegend)
			},
		})
	}

	const handleModalSearch = () => {
		const { searchInput, searchableItems } = state

		if (!searchInput || !searchableItems) return
		const keyUp = useEvent<KeyboardEvent>(searchInput, 'keyup')

		keyUp.register(({ e }) => {
			if (!e.target) return
			const { value } = e.target as HTMLInputElement

			if (value && value.length > 0) {
				const regex = new RegExp(value, 'i')

				for (let i = 0; i < searchableItems.length; i++) {
					const attrsAttribute = searchableItems[i].getAttribute(selector.searchableAttributes)
					const questionName = searchableItems[i].getAttribute(selector.searchableQuestionName)

					if (attrsAttribute === null) continue
					if (questionName === null) continue

					if (
						!searchableItems[i].innerText.match(regex) &&
						!attrsAttribute.match(regex) &&
						!questionName.match(regex)
					) {
						cn.addClass(searchableItems[i], modifier.hidden)
					} else {
						cn.removeClass(searchableItems[i], modifier.hidden)
					}
				}
			} else {
				for (let i = 0; i < searchableItems.length; i++) {
					cn.removeClass(searchableItems[i], modifier.hidden)
				}
			}
		})
	}

	const handleEvents = () => {
		window.addEventListener('resize', handleTableSizes)
	}

	const handleInitSelectors = () =>
		setState({
			...getCrosstableSelectors(),
			staticCols: getStaticCols(),
			cols: getCols(),
			headCols: getHeadCols(),
		})

	const init = () => {
		handleInitSelectors()
		handleTableSizes()
		destroyLoader()
		handleModalSearch()
		handleEvents()
		handleSortable()
		handleModalEvents()
	}

	return {
		init,
	}
}
