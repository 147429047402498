export const header = {
	selector: {
		own: '.js-header',
		logo: '.js-header-logo',
		filterHandler: '.js-filter-handler',
		actions: '.js-header-actions',
		account: '.js-header-account',
	},
	size: {
		scrolledDiff: 20,
	},
}
