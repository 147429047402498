import { module } from 'constant'
import { createSelector, getHeader } from 'selectors'

const getFilterHandlerSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.filter.selector.handler },
		true,
	)

const getFilterResetSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.filter.selector.reset },
		true,
	)

const getActiveFiltersSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.filter.selector.active })

export const getFilterSelectors = () => {
	const header = getHeader()

	if (!header) return null
	return {
		filterHandlers: getFilterHandlerSelector(header),
		filterResets: getFilterResetSelector(),
		activeFilters: getActiveFiltersSelector(),
	}
}

export const getActiveFilters = () => {
	const activeFilters = getActiveFiltersSelector()

	if (!activeFilters) return null
	return activeFilters
}
