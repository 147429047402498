import { useEvent, useState } from 'hooks'
import { fileManagerSelector } from 'selectors'
import { cn } from 'utils'
import { modifier, module } from 'constant'

const { fileManager } = module

export const FileManager = () => {
	const { state, setState } = useState({
		...fileManagerSelector.getFileManagerSelectors(),
	})

	const handleFolderEvent = (scope: HTMLElement) => {
		const click = useEvent(scope, 'click')

		click.register(() => {
			const { parentNode } = scope

			if (!parentNode) return
			const sublist = fileManagerSelector.getSublist(parentNode as HTMLElement)
			const folderIcon = fileManagerSelector.getFolderIcon(parentNode as HTMLElement)

			if (!sublist || !folderIcon) return
			if (cn.hasClass(sublist, modifier.active)) {
				cn.removeClass(sublist, modifier.active)
				cn.removeClass(folderIcon, modifier.active)
				cn.removeClass(folderIcon, fileManager.icon.folderOpen)
				cn.addClass(folderIcon, fileManager.icon.folderClose)
			} else {
				cn.addClass(sublist, modifier.active)
				cn.addClass(folderIcon, modifier.active)
				cn.removeClass(folderIcon, fileManager.icon.folderClose)
				cn.addClass(folderIcon, fileManager.icon.folderOpen)
			}
		})
	}

	const initFolders = () => {
		const { folders } = state

		if (!folders) return
		const { length } = folders

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const f = folders[i]

			if (!f) continue
			handleFolderEvent(f)
		}
	}

	const init = () => {
		initFolders()
	}

	return {
		init,
	}
}
