export function copyToClipboard(text) {
	var $temp = $('<input>')
	$('body').append($temp)
	$temp.val(text).select()
	document.execCommand('copy')
	$temp.remove()
}

export function checkPasswordStrength(text) {
	var input = $('.passwordInput')
	var str = input.val()
	var score = 10
	var cls = 'weak'

	$('.password-status').remove()
	$('#password-status').remove()

	input.closest('.passwordInputContainer').removeClass('weak')
	input.closest('.passwordInputContainer').removeClass('medium')
	input.closest('.passwordInputContainer').removeClass('strong')
	input.closest('.passwordInputContainer').removeClass('very-strong')

	if (str == '') {
		return false
	}

	if (str.length < 6) {
		score = score - 8
		text = 'The password is too short'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<li class="password-status">' + text + '</li>')
	}
	if (str.search(/\d/) == -1) {
		score = score - 2
		text = 'The password does not contain numbers'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<li class="password-status">' + text + '</li>')
	}
	if (str.search(/[a-zA-Z]/) == -1) {
		score = score - 2
		text = 'The password does not contain letters'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<li class="password-status">' + text + '</li>')
	}
	if (str.search(/[a-z]/) == -1) {
		score = score - 1
		text = 'The password does not contain lower letters'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<li class="password-status">' + text + '</li>')
	}
	if (str.search(/[A-Z]/) == -1) {
		score = score - 1
		text = 'The password does not contain upper letters'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<li class="password-status">' + text + '</li>')
	}
	if (str.search(/[\@\#\$\%\^\&\*\(\)\_\+\?\.\!\;]/) == -1) {
		score = score - 3
		text = 'The password does not contain special chars'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<li class="password-status">' + text + '</li>')
	}

	if (score == 10) {
		cls = 'very-strong'
		text = 'The password is very strong'
		input
			.closest('.passwordInputContainer')
			.parent()
			.append('<p id="password-status">' + text + '</p>')
	} else if (score > 7) {
		cls = 'strong'
	} else if (score > 4) {
		cls = 'medium'
	} else {
		cls = 'weak'
	}
	input.closest('.passwordInputContainer').addClass(cls)
}
