import { bp } from './breakpoint'
import { crosstable } from './crosstable'
import { drawer } from './drawer'
import { fileManager } from './fileManager'
import { filter } from './filter'
import { header } from './header'
import { menu } from './menu'
import { overlay } from './overlay'

type Module = {
	bp: typeof bp
	crosstable: typeof crosstable
	drawer: typeof drawer
	fileManager: typeof fileManager
	filter: typeof filter
	header: typeof header
	menu: typeof menu
	overlay: typeof overlay
}

export const module: Module = {
	bp,
	crosstable,
	drawer,
	fileManager,
	filter,
	header,
	menu,
	overlay,
}

export * from './modifier'
