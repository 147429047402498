export type AnyState = { [key: string]: any }
export type ConnectArgs = AnyState
type Curry = (args: AnyState) => void
type SetState<S> = (values: AnyState, callback?: (actualState: S & AnyState) => void) => void
type SubscribeCallback = (state: AnyState) => void
type ConnectCallback<S> = (props: Connect<S>) => Curry | void
export type Connect<S> = {
	getState: () => S & AnyState
	setState: SetState<S>
	connect: (callback: ConnectCallback<S>) => Curry
	state: S & AnyState
}
type UseState<S> = {
	connect: (callback: ConnectCallback<S>) => Curry
	setState: SetState<S>
	getState: () => S & AnyState
	subscribe: (callback: SubscribeCallback) => void
	state: S & AnyState
}

const makeProperties = (values: AnyState) => {
	const valuesKeys = Object.keys(values)
	const valuesKeysLength = valuesKeys.length
	const obj = {} as AnyState

	for (let i = 0; i < valuesKeysLength; i++) {
		const key = valuesKeys[i]
		const value = values[key]
		obj[key] = {
			value,
			writeable: true,
			enumerable: true,
			configurable: true,
		}
	}

	return obj
}

export const useState = <S>(initialState?: S & AnyState): UseState<S> => {
	const events = [] as SubscribeCallback[]
	const state = initialState || ({} as S & AnyState)

	const subscribe = (callback: (state: AnyState) => void) => {
		events.push(callback)
	}

	const dispatchSubscribes = () => {
		const eventsLength = events.length
		for (let i = 0; i < eventsLength; i++) {
			events[i](getState())
		}
	}

	const getState = () => state as S

	const setState = (values: AnyState, callback?: (state: S & AnyState) => void) => {
		Object.defineProperties(state, makeProperties(values))
		dispatchSubscribes()
		if (callback) {
			callback(getState())
		}
	}

	const connect = (callback: ConnectCallback<S>) => {
		if (callback) {
			const dispatch = callback({ getState, setState, connect, state })

			if (typeof dispatch === 'function') {
				return (args: ConnectArgs) => {
					dispatch(args)
				}
			}
		}
		return () => {}
	}

	return {
		connect,
		setState,
		getState,
		subscribe,
		state,
	}
}
