export var alertAction = {
	searchChange: function(_this, e) {
		var alertSearchReset = $("input[name='alertSearchReset']")
		alertSearchReset.show()
	},
	clearSearch: function(_this) {
		var form = $(_this).closest('form')
		var searchInput = $("input[name='alertSearchInput']")
		searchInput.val('')
		$(_this).hide()
		form.submit()
	},
	change: {
		submitForm: function(_this) {
			$(_this)
				.closest('form')
				.submit()
		},
		disableEdit: function() {
			$('.alertSolve').css({ opacity: '0.5', 'pointer-events': 'none' })
		},
	},
	status: {
		inprogress: function(_this) {
			var thisAlert = $(_this).closest('#detailWrapper')
			if (
				$(_this)
					.parent()
					.parent()
					.hasClass('smilesOpened')
			) {
				$(_this)
					.parent()
					.parent()
					.removeClass('smilesOpened')
				$(_this)
					.parent()
					.parent()
					.addClass('smilesClosed')
				$(_this)
					.closest('.alertDetailWrapper')
					.find('.smiles')
					.animate({ width: 'toggle' }, 250)
			}
			thisAlert.find('.inprogress').addClass('active')
			thisAlert.find('.solved, .unsolved, .smilebad, .smileneutral, .smilegood').removeClass('active')
			thisAlert
				.find('.elapsedTime')
				.text('')
				.append('&nbsp;')
			//thisAlert.find('.newTime').text('0 d.');
			thisAlert.find('.status').val(1)
			thisAlert.find('.result').val('')
		},
		solved: function(_this) {
			var thisAlert = $(_this).closest('#detailWrapper')
			if (
				$(_this)
					.parent()
					.parent()
					.hasClass('smilesOpened')
			) {
				$(_this)
					.parent()
					.parent()
					.removeClass('smilesOpened')
				$(_this)
					.parent()
					.parent()
					.addClass('smilesClosed')
				$(_this)
					.closest('.alertDetailWrapper')
					.find('.smiles')
					.animate({ width: 'toggle' }, 250)
			}
			thisAlert.find('.solved').addClass('active')
			thisAlert.find('.inprogress, .unsolved, .smilebad, .smileneutral, .smilegood').removeClass('active')
			thisAlert
				.find('.elapsedTime')
				.text('')
				.append('&nbsp;')
			//thisAlert.find('.newTime').text('0 d.');
			thisAlert.find('.status').val(2)
			thisAlert.find('.result').val('')
		},
		solvedSmiles: function(_this) {
			var thisAlert = $(_this).closest('#detailWrapper')
			thisAlert.find('.alertStatus').fadeOut(200, function() {
				thisAlert.find('.smiles').fadeIn(300)
			})
		},
		back: function(_this) {
			var thisAlert = $(_this).closest('#detailWrapper')
			thisAlert.find('.smiles').fadeOut(200, function() {
				thisAlert.find('.alertStatus').fadeIn(300)
			})
			thisAlert.find('.unsolved').addClass('active')
			thisAlert.find('.inprogress, .solved, .smilebad, .smileneutral, .smilegood').removeClass('active')
			thisAlert
				.find('.elapsedTime, .newTime')
				.text('')
				.append('&nbsp;')
			thisAlert.find('.status').val(0)
			thisAlert.find('.result').val('')
		},
		result: {
			bad: function(_this) {
				var thisAlert = $(_this).closest('#detailWrapper')
				$(_this).addClass('active')
				thisAlert.find('.smilegood, .smileneutral').removeClass('active')
				thisAlert.find('.status').val(2)
				thisAlert.find('.result').val(3)
			},
			neutral: function(_this) {
				var thisAlert = $(_this).closest('#detailWrapper')
				$(_this).addClass('active')
				thisAlert.find('.smilebad, .smilegood').removeClass('active')
				thisAlert.find('.status').val(2)
				thisAlert.find('.result').val(4)
			},
			good: function(_this) {
				var thisAlert = $(_this).closest('#detailWrapper')
				$(_this).addClass('active')
				thisAlert.find('.smilebad, .smileneutral').removeClass('active')
				thisAlert.find('.status').val(2)
				thisAlert.find('.result').val(5)
			},
		},
	},
}

export function openAlertModal(rowId) {
	var elem = document.getElementById('modal-' + rowId)
	M.Modal.init(elem, {
		onOpenEnd: function(el) {
			M.Tabs.init(el.querySelector('.alertDetailTabs'))
		},
		onCloseEnd: function(el) {
			setTimeout(function() {
				$(el)
					.children()
					.empty()
			}, 200)

			var reloadlink = $(el)
				.closest('.alerts-table')
				.data('reloadlink')
			$.nette.ajax({
				type: 'POST',
				dataType: 'json',
				url: reloadlink,
			})
		},
		inDuration: 0,
		outDuration: 0,
		dismissible: false,
	})
	var modalInstance = M.Modal.getInstance(elem)
	modalInstance.open()
}

export function closeAlertModal(rowId) {
	var modal = document.getElementById('modal-' + rowId)
	var modalInstance = M.Modal.getInstance(modal)
	modalInstance.close()
}

export const alertsEvents = () => {
	$(document).on('click', '.submenu a', function() {
		$(this)
			.closest('.submenu')
			.find('i')
			.removeClass('active')
		$(this)
			.find('i')
			.addClass('active')
	})
	$(document).on(
		'keyup',
		'.alertSearchInput',
		window.debounce(function() {
			$(this)
				.closest('form')
				.submit()
		}, 500),
	)
}
