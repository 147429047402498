export const resizetrigger = function(element, options) {
	var settings = $.extend(
		{
			name: '',
		},
		options,
	)

	var bottom = $(element).outerHeight(true),
		right = $(element).outerWidth(true)

	setInterval(function() {
		var bottomactual = $(element).outerHeight(true),
			rightactual = $(element).outerWidth(true)

		if (bottomactual !== bottom || rightactual !== right) {
			bottom = bottomactual
			right = rightactual

			$(element).trigger(settings.name)
		}
	}, 1)

	return element
}
