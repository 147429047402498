export const useOutsideClick = (ref: HTMLElement, callback: () => void) => {
	const handleClick = (e: MouseEvent) => {
		const { target } = e

		if (!target) return
		if (ref && !ref.contains(target as Node)) {
			callback()
		}
	}

	document.addEventListener('click', handleClick)
}
