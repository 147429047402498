export const elementExists = (el: any) => {
	while (el) {
		if (el === document.body) {
			return true
		}
		// eslint-disable-next-line no-param-reassign
		el = el.parentNode as HTMLElement
	}
	return false
}
