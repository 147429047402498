import { wrap, Tooltip } from 'highcharts'
import M from 'materialize-css'
import $ from 'jquery'

export const client = () => {
	wrap(Tooltip.prototype, 'hide', function(defaultCallback) {
		if (this.chart.userOptions.chart.type !== 'solidgauge') {
			defaultCallback.apply(this, Array.prototype.slice.call(arguments, 1))
		}
	})

	// vlajecky
	const languageCheckbox = document.getElementById('languageCheckbox')
	if (!languageCheckbox) return

	languageCheckbox.addEventListener('click', function(event) {
		const language = event.target.checked === false ? 'ar' : 'en'
		const link = languageCheckbox.getAttribute('data-link')
		$.nette.ajax({
			type: 'POST',
			url: link,
			data: {
				language,
			},
		})
	})

	const initSegmentationFilter = () => {
		const segmentationFilter = document.querySelector('js-segmentation-filter')
		if (!segmentationFilter) return

		const segmentationSelect = segmentationFilter.querySelectorAll('select')
		if (!segmentationSelect) return

		M.FormSelect.init(segmentationSelect)
	}

	return {
		initSegmentationFilter
	}
}
