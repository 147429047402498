import { module } from 'constant'
import { createSelector } from 'selectors'

const { selector } = module.crosstable

const getCrosstableSelector = () => createSelector<HTMLElement>({ selector: selector.own })
const getContentSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.content })
const getLoaderSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.loader })
const getStaticSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.static })
const getRowsHeadSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.headRow })
const getRowsContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.rows })
const getRowsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.row },
		true,
	)
const getActiveRowsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalActiveRows })
const getActiveColumnsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalActiveCols })
const getModalLegendSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalLegend })
const getModalConfigSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalConfig })
const getModalConfigQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalConfigQuestions })
const getHeadColsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.headCol },
		true,
	)
const getHeadColSplitItemsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.headColSplitItem },
		true,
	)
const getColsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.col },
		true,
	)
const getColsSplitsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.colSplit },
		true,
	)
const getColsSplitItemsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.colSplitItem },
		true,
	)
const getStaticColsSelector = (scope?: HTMLElement, value?: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.staticCol, value },
		true,
	)
const getStaticRowsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.staticRow },
		true,
	)
const getStaticColsSplitsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.staticColSplit },
		true,
	)
const getFirstRowSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.row })
const getSearchInputSelector = (scope?: HTMLElement) => createSelector({ scope, selector: selector.searchInput })
const getSearchableItemsSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: selector.searchableItem },
		true,
	)
const getEditorQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorQuestions })
const getEditorAvailableQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorAvailableQuestions })
const getEditorAssignedQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorAssignedQuestions })
const getEditorQuestionnaireSelectSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorQuestionnaireSelect })
const getEditorQuestionnaireSubmitSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorQuestionnaireSubmit })

export const getCrosstableSelectors = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return {
		content: getContentSelector(scope),
		loader: getLoaderSelector(scope),
		staticContainer: getStaticSelector(scope),
		staticColsSplits: getStaticColsSplitsSelector(scope),
		staticRows: getStaticRowsSelector(scope),
		rowsContainer: getRowsContainerSelector(scope),
		rows: getRowsSelector(scope),
		headRows: getRowsHeadSelector(scope),
		activeRows: getActiveRowsSelector(scope),
		activeColumns: getActiveColumnsSelector(scope),
		modalLegend: getModalLegendSelector(scope),
		modalConfig: getModalConfigSelector(scope),
		modalConfigQuestions: getModalConfigQuestionsSelector(scope),
		searchInput: getSearchInputSelector(scope),
		searchableItems: getSearchableItemsSelector(scope),
		firstRow: getFirstRow(),
		firstRowCols: getFirstRowCols(),
	}
}

export const getCrosstableEditorSelectors = () => {
	const scope = getEditorQuestionsSelector()

	if (!scope) return null
	return {
		editorQuestions: scope,
		editorAvailableQuestions: getEditorAvailableQuestionsSelector(scope),
		editorAssignedQuestions: getEditorAssignedQuestionsSelector(scope),
		editorQuestionnaireSelect: getEditorQuestionnaireSelectSelector(scope),
		editorQuestionnaireSubmit: getEditorQuestionnaireSubmitSelector(scope),
	}
}

export const getFirstRow = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	const rowsContainer = getRowsContainerSelector(scope)

	if (!rowsContainer) return null
	const firstRow = getFirstRowSelector(rowsContainer)

	if (!firstRow) return null
	return firstRow
}

export const getFirstRowCols = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	const firstRow = getFirstRowSelector(scope)

	if (!firstRow) return null
	const firstRowCols = getColsSelector(firstRow)

	if (!firstRowCols) return null
	return firstRowCols
}

export const getHeadCols = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return getHeadColsSelector(scope)
}

export const getHeadColSplitItems = (scope: HTMLElement) => {
	if (!scope) return null
	return getHeadColSplitItemsSelector(scope)
}

export const getCols = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return getColsSelector(scope)
}

export const getColsSplits = (scope: HTMLElement) => {
	if (!scope) return null
	return getColsSplitsSelector(scope)
}

export const getColsSplitItems = (scope: HTMLElement) => {
	if (!scope) return null
	return getColsSplitItemsSelector(scope)
}

export const getStaticCols = (value?: string | number) => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	if (value) return getStaticColsSelector(scope, String(value))
	return getStaticColsSelector(scope)
}
