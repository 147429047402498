import { module } from 'constant'
import { createSelector } from 'selectors'

const getDrawerSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.drawer.selector.own }) as HTMLElement

const getDrawersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.drawer.selector.own },
		true,
	) as NodeListOf<HTMLElement>

const getDrawersHandlersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.drawer.selector.handler },
		true,
	) as NodeListOf<HTMLElement>

const getSubdrawersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.drawer.selector.sub },
		true,
	) as NodeListOf<HTMLElement>

const getSubdrawersHandlersSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.drawer.selector.subHandler },
		true,
	) as NodeListOf<HTMLElement>

const getSubdrawersClosesSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: module.drawer.selector.subClose },
		true,
	) as NodeListOf<HTMLElement>

export const getDrawerSelectors = () => {
	return {
		drawers: getDrawersSelector(),
		drawersHandlers: getDrawersHandlersSelector(),
		subdrawers: getSubdrawersSelector(),
		subdrawersHandlers: getSubdrawersHandlersSelector(),
		subdrawersCloses: getSubdrawersClosesSelector(),
	}
}

export const getDrawer = (scope?: HTMLElement) => {
	const drawer = getDrawerSelector(scope)

	if (!drawer) return null
	return drawer
}
